<template>
    <v-app id="loginBackground">
        <v-container fill-height class="mx-0">
            <v-col xs="12" sm="12" md="6" lg="7" xl="5" class="align-center justify-center"> 
                <div class="centrar-elementos">
                    <div slot="widget-content">
                        <v-alert type="error" dismissible v-model="showError">
                            {{errorMessage}}
                        </v-alert>
                    </div>
                    <div class="column align-center">
                        <div class="my-3" style="text-align: center !important;">
                            <img :src="logoCliker" alt="Logo del login" class="align-center" id="logoLogin2" width="167.25">
                        </div>
                        <p class="gestor-empresarial ">
                            <span class="negrita">Gestiona el talento humano y paga nómina</span>
                            <br>desde un mismo lugar.
                        </p>
                    </div>
                    <v-form class="login-inputs col-lg-8 col-md-12 col-s-12 col-xs-12  " style="margin-right:auto; margin-left:auto;" >
                        <ValidationObserver ref="form">
                            <ValidationProvider v-slot="{ errors }" name="usuario" rules="required">
                                <v-text-field 
                                    outlined
                                    class="mb-3"
                                    name="Usuario" 
                                    id="usuario" 
                                    type="text" 
                                    v-model="user.usuario" 
                                    placeholder="usuario"
                                    :error-messages="errors" 
                                    required
                                >
                                    <template slot="prepend-inner">
                                        <i class="material-icons-outlined my-0 color-color">person</i>
                                        <v-icon class="icono-pipe ml-2" style="margin-top:-3px;"></v-icon>
                                    </template>
                                </v-text-field >
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" name="contraseña" rules="required">
                                <v-text-field 
                                    outlined
                                    class="mb-3"
                                    prepend-inner-icon="lock" 
                                    name="Contraseña" 
                                    id="password" 
                                    type="password" 
                                    v-model="user.password" 
                                    placeholder="contraseña"
                                    @keyup.enter="validate"
                                    :error-messages="errors" 
                                    required
                                >
                                    <template slot="prepend-inner">
                                        <i class="material-icons-outlined my-0 color-color">lock</i>
                                        <i class="material-icons-outlined icono-pipe ml-2" style="margin-top:-3px;"></i>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </ValidationObserver>
                        <v-spacer></v-spacer>
                        <v-btn id="btnLogin" @click="validate" :loading="loading">Iniciar sesión</v-btn>
                    </v-form>
                </div>
            </v-col>
        </v-container>
    </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data: () => ({
        loading   : false,
        showError : false,
        user      : {
            email   : '',
            usuario : '',
            password: '',
            sistema: 'nominas',
        },
        url_logo  : "/",
    }),
    computed:{
        ...mapState('auth',['error','errorMessage','datos','token', 'menu']),
        logoCliker() {
            return '/static/bg/cliker_login.png'
        }
    },
    methods:{
        ...mapActions('auth',['singIn']),
        init() {
            var iconos = {
                "color":"white",
            }
            $(document).ready(function() {
                $(".theme--light.v-icon").css(iconos);
            })
            this.showError = this.error;
            document.getElementById("usuario").focus();
        },
        validate(){
            this.$refs.form.validate().then(success => {
                if(!success) {
                    this.loading = false;
                    return;
                }
                this.login();
            });
        },
        async login(){
            this.loading = true;
            await this.singIn(this.user);
            this.showError = this.error;

            if(this.error == true){
                this.loading = false;
                this.user.password = '';
                document.getElementById("password").focus();
            }
            else{
                this.$session.set('usuario',this.datos);
                this.$session.set('menu', this.menu);
                this.$session.set('token', this.token);

                let session = this.$session.get('usuario');
                let rol     = session.rol.name;
                this.$emit('loggedIn');
                let primeraVez = session.configuracion;
                    
                if (rol == "admin" && primeraVez) {
                    this.$router.push("/bienvenido");
                } 
                else if (rol == "admin-empresa" && primeraVez) {
                    this.$router.push("/bienvenido");
                } 
                else if (rol == "checador") {
                    this.$router.push("/asistencia/checador-webcam");
                } 
                else {
                    this.$router.push("/dashboard");
                }
            }
        }
    },
    mounted(){
        this.init()
    }
}
</script>
<style scoped lang="css">
    #loginBackground {
        margin: 0%;
        padding: 0%;
        background: url("/static/bg/fondo_login.png");
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        background-position: right center;
        top: 0;
        left: 0;
        content: "";
        z-index: 0;
        position: absolute;
        background-size: 65%;
    }
    #logoLogin2 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    }

    #btnLogin {
        background-color: #1E2245;
        border-radius: 23px;
        box-shadow: 5px 10px 24px 0 rgba(0, 0, 0, 0.1),
            inset 10px 11px 17px 0 rgba(119, 124, 185, 0.11);
        width: 100%;
        height: 46px;
        color: rgba(255, 255, 255, 0.95) !important;
        font-family: "Montserrat";
        font-style: normal;
        font-size: 16px !important;
        font-weight: 600;
        line-height: 17px;
        text-align: center !important;
        text-transform: none !important;
    }
    
    div.v-input__slot::before {
        border-color: #3e6086 !important;
    }

    .gestor-empresarial {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-size: 21px !important;
        line-height: 35px !important;
        text-align: center !important;
        color: #1E2245 !important;
        margin-bottom: 100px;
    }

    .negrita {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 24px;
        opacity: 0.8;
    }

    .centrar-elementos {
        text-align: center;
    }

    .color-color {
        color: #e0e0e0 !important;
    }

    @media screen and (max-width: 1366px) and (min-width: 960px){
        .gestor-empresarial {
            margin-bottom: 50px;
        }
    }
    @media screen and (max-width: 959px) and (min-width: 160px) {
        #loginBackground {
            background: #ffffff;
        }
    }

    .material-icons-outlined {
        font-family: 'Material Icons Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }  
</style>